<template>
  <v-container id="employee_view" fluid tag="section">
    <v-card
      v-if="selectedEmployee && selectedUser"
      style="border-radius: 8px"
      elevation="3"
      active-class="b-radius"
      class="mt-2"
    >
      <employee-full-form />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import EmployeeFullForm from "./components/EmployeeFullForm";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "Empoloyee",
  components: { EmployeeFullForm },
  mixins: [sharedActions],
  data() {
    return {
      activePanel: 0,
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
      selectedEmployee: "employee/getSelectedEmployee",
    }),
  },
  mounted() {
    this.setSelectedUser(null);
    this.setSelectedEmployee(null);

    this.setIsSending(true);
    this.getEmployee(this.$route.params.id)
      .then((response) => {
        this.setSelectedUser(response.user);
        this.setSelectedEmployee(response);
      })
      .catch((error) => {
        errorNotification("user_can_not_be_loaded", error, false);
        this.$router.push({ name: "employees" });
        this.clearCache("employee-list-v2");
      })
      .finally(() => {
        this.setIsSending(false);
      });
  },
  methods: {
    ...mapActions({
      getEmployee: "employee/get",
    }),
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
      setSelectedEmployee: "employee/SET_SELECTED_EMPLOYEE",
    }),
  },
};
</script>
